.map-container button {
    all: unset;
}

.react-tabs__tab-list {
    border: 0px;
    border-bottom: 1px solid #333333;
}

.react-tabs__tab {
    border: 0px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #777777;
}

.react-tabs__tab--selected {
    color: #333333;
    border-bottom: 2px solid #333333;
}
