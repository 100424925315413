.header {
    display: flex;
    width: 100%;
    height: var(--header-height);
    background-color: black;
}

.header-left {
    width: calc(var(--burger-menu-width) + 2 * var(--burger-menu-margin));
}

.header-center {
    flex-grow: 1;
    text-align: center;
}

.header-right {
    margin: 1px;
    padding-right: 5px;
}
