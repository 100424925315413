.neighborhood-card {
    font-size: 16px;
    font-weight: bold;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f7f7f7;

    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    margin: 10px;
    font-family: 'Raleway', sans-serif;
    color: #555555;
}
