/* Position and sizing of burger button */
/* TODO(Andy): It sucks to have this in a fixed position (vs relative to the container), but given how our layout is
               structured and how the hamburger menu works I don't see any other way */
.bm-burger-button {
    position: absolute;
    width: var(--burger-menu-width);
    height: var(--burger-menu-height);
    left: var(--burger-menu-margin);
    top: var(--burger-menu-margin);
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #eee;
    height: 15% !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #eee;
    opacity: 1 !important;
    height: 15% !important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px !important;
    width: 24px !important;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
    height: 24px !important;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 1em 0.5em var(--burger-menu-height) 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    display: flex;
    flex-direction: column;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

.bm-items-top {
    flex-grow: 1;
    display: flex !important;
    flex-direction: column !important;
}

.bm-items-bottom {
    display: flex !important;
    flex-direction: column !important;
    padding-bottom: 10px;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.bm-menu a {
    all: unset;
    color: #b8b7ad;
    font-weight: 600;
    text-decoration: none;
    margin-bottom: 5px;
    line-height: 1.2em;
    font-size: 22px;
    cursor: pointer;
}

.bm-menu a:link {
}

.bm-menu a:visited {
}

.bm-menu a:focus {
}

.bm-menu a:hover {
}

.bm-menu a:active {
}
