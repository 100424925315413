/* TODO(Andy): hacky, but seems the only way to get control over the main container without defining a whole new theme */
#auth-container {
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.auth-container > button {
    align-self: center;
}

.auth-input {
    font-size: 1.3rem;
    padding: 5px;
    margin-top: 5px;
}
