@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;700&display=swap');

:root {
    --primary: #def358;
    --secondary: #99a83e;
    --yellow: #6f7a29;
    --black: #000;
    --white: #fff;
    --gray: #c0b9cf;
    --font-primary: 'Inter', sans-serif;
    --font-secondary: 'Indie Flower', cursive;
    --font-title: 'Architects Daughter', cursive;

    /* Header */
    --header-height: 50px;

    /* Hamburger menu */
    --burger-menu-margin: 10px;
    --burger-menu-width: calc((var(--header-height) - 2 * var(--burger-menu-margin)) * 1.1);
    --burger-menu-height: calc(var(--header-height) - 2 * var(--burger-menu-margin));
}

/* Typography */
h1 {
    font-size: 100px;
    font-family: 'Architects Daughter', cursive;
}

/* changed from 100px before -- MAKE responsive */
h2 {
    font-size: 80px;
}

h3 {
    font-size: 40px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    line-height: 1.2em;
}

p {
    font-size: 14px;
    line-height: 1.4em;
}

:root {
    height: 100%;
}

html {
    height: 100%;
    scroll-behavior: smooth;
}

* {
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.mobile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    @media (max-width: 768px) {
        min-width: 100vw;
    }
}

.mobile-header {
    height: var(--header-height);
    background-color: #000;
}

.mobile-container h1 {
    font-size: 4rem;
    margin-bottom: 2rem;
}

.mobile-container button {
    width: 300px;
    padding: 1rem;
    margin: 0.5rem 0;
    font-size: 1.25rem;
    color: white;
    background-color: black;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 10px;
}

.mobile-container button:hover {
    background-color: #444; /* Slightly lighter black for hover effect */
}

/* Landing Page */

.mobile-container > .landing {
    min-height: calc(100vh - var(--header-height));
    @media (max-width: 768px) {
        min-width: 100vw;
    }
    display: inherit;
    flex-direction: inherit;
    background-color: var(--primary);
    align-items: center;
    justify-content: inherit;
    /*padding: 20px;*/
}
/* Explore */

.mobile-container > .explore-container {
    min-height: calc(100vh - var(--header-height));
    min-width: 100vw;
    display: inherit;
    flex-direction: inherit;
    justify-content: start;
    padding: 10px;
    background-color: #222;
    color: #fff;
    align-items: center;
}

.explore-container h1 {
    font-size: 2.3rem; /* 2.4rem seems to work good to keep a single-line title on most mobile devices */
    margin-bottom: 0.5rem;
}
.explore-container h2 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
    text-align: center;
    font-weight: 400;
}

.explore-container button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: #000;
    background-color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 2rem;
    min-width: 180px;
}

.explore-container button:hover {
    background-color: #ddd;
}
