.divider-loading {
    padding-top: 15px;
    padding-bottom: 15px;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
}

.loading-dot {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: rgb(152 89 193);
    margin: 0 10px;
    animation: loading 1s ease-in-out infinite;
}

.loading-dot:nth-child(2) {
    animation-delay: 0.1s;
}

.loading-dot:nth-child(3) {
    animation-delay: 0.2s;
}

@keyframes loading {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}