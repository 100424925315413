.pager-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.explore-location-button-container {
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}
