.explore-destination-card {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    min-height: 400px;
    box-sizing: border-box;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: row;
    width: fit-content; /* Align center on desktop */
    margin: auto; /* Align center on desktop */
}

.explore-destination-card .main-image {
    width: 100%;
    height: auto;
    min-height: 400px;
    object-fit: cover;
    object-position: center;
}

.explore-destination-card .image-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 10px;
}

.explore-destination-card .image-footer h3 {
    margin: 0;
}

.explore-destination-card .description-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.explore-destination-card .description-container .information {
    flex: 1 0 auto;
}

.explore-destination-card .description-container p {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
}

.explore-destination-card .description-container .highlight {
    font-weight: bold;
    font-size: 18px;
}

.explore-destination-card .description-container .button-container {
    flex-shrink: 0;
}

.explore-destination-card .description-container .button-container button {
    background-color: #d3e96b;
    width: 100%;
}

.explore-destination-card .description-container ul,
li {
    margin: 0;
    padding: 0;
    background-color: initial;
    border-radius: initial;
    box-shadow: initial;
    list-style-type: disc;
    font-weight: unset;
}

.explore-destination-card .description-container ul {
    margin-top: -10px;
}

.explore-destination-card .description-container li {
    margin-top: 10px;
    margin-left: 20px;
}

.explore-destination-card .icon-container {
    position: absolute;
    top: 7px;
    right: 7px;
}
