.form-container {
    min-height: 400px;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.input-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 16px;
    margin-bottom: 30px;
    width: 100%;
    flex-wrap: wrap;
    max-width: 1400px;
}

.input-wrapper {
}

.input-wrapper:nth-child(1) {
    flex: 1;
    min-width: 300px;
}

.input-wrapper:nth-child(2) {
    flex: 1;
    min-width: 100px;
    max-width: 200px;
}

.input-wrapper:nth-child(3) {
    flex: 1;
    min-width: 100px;
    max-width: 200px;
}

.input-wrapper:nth-child(4) {
    flex: 1;
    min-width: 250px;
}

.input-wrapper:nth-child(5) {
    flex: 2;
    min-width: 320px;
}
