.masonry {
    width: 100% !important;

    @media (min-width: 768px) {
        width: calc(100% - 20px) !important;
        margin-left: -10px !important; /* Leave room for the scrollbar */
    }
}
.grid-image {
    width: 100%;
    border-radius: 15px;
    border: 5px solid white;
    cursor: pointer;
    transition: border-color 0.3s ease;
    object-fit: cover;
    display: block;
}

.grid-image.selected {
    border-color: #6942d7;
}

.bottom-section {
    margin-bottom: 20px;
}
